"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserTypeEnum = exports.UserActivationStateEnum = exports.UpdatePartDataType = exports.SuggestionItemTypeEnum = exports.StudyTransactionState = exports.StudyStatusEnum = exports.StudyLinkCatalogEnum = exports.StudyFileTypeEnum = exports.StripeSubscriptionStatus = exports.StripeProductEnum = exports.StringFilterType = exports.SortOrderEnum = exports.Role = exports.PartsOrderByEnum = exports.PaidStatusEnum = exports.NewsletterOrderBy = exports.NewPricesDataType = exports.MaterialsOrderByEnum = exports.ManufacturerType = exports.ManufacturersOrderByEnum = exports.ListItemUpdateType = exports.GroupByEnum = exports.GicsOrderByEnum = exports.FileStatusEnum = exports.ConstructMasterGroup = exports.ComparisonEnum = exports.BillingSchemeEnum = exports.BillingReasonEnumType = exports.BillingIntervalEnum = exports.BillingEnumType = void 0;
var BillingEnumType;
(function (BillingEnumType) {
    BillingEnumType["ChargeAutomatically"] = "chargeAutomatically";
    BillingEnumType["SendInvoice"] = "sendInvoice";
})(BillingEnumType = exports.BillingEnumType || (exports.BillingEnumType = {}));
var BillingIntervalEnum;
(function (BillingIntervalEnum) {
    BillingIntervalEnum["Day"] = "day";
    BillingIntervalEnum["Month"] = "month";
    BillingIntervalEnum["Week"] = "week";
    BillingIntervalEnum["Year"] = "year";
})(BillingIntervalEnum = exports.BillingIntervalEnum || (exports.BillingIntervalEnum = {}));
var BillingReasonEnumType;
(function (BillingReasonEnumType) {
    BillingReasonEnumType["Manual"] = "manual";
    BillingReasonEnumType["Subscription"] = "subscription";
    BillingReasonEnumType["SubscriptionCreate"] = "subscriptionCreate";
    BillingReasonEnumType["SubscriptionCycle"] = "subscriptionCycle";
    BillingReasonEnumType["SubscriptionUpdate"] = "subscriptionUpdate";
    BillingReasonEnumType["Upcoming"] = "upcoming";
})(BillingReasonEnumType = exports.BillingReasonEnumType || (exports.BillingReasonEnumType = {}));
var BillingSchemeEnum;
(function (BillingSchemeEnum) {
    BillingSchemeEnum["PerUnit"] = "perUnit";
    BillingSchemeEnum["Tiered"] = "tiered";
})(BillingSchemeEnum = exports.BillingSchemeEnum || (exports.BillingSchemeEnum = {}));
/** Comparison signs for Search Query */
var ComparisonEnum;
(function (ComparisonEnum) {
    ComparisonEnum["BiggerThan"] = "biggerThan";
    ComparisonEnum["BiggerThanOrEqual"] = "biggerThanOrEqual";
    ComparisonEnum["Equal"] = "equal";
    ComparisonEnum["LessThan"] = "lessThan";
    ComparisonEnum["LessThanOrEqual"] = "lessThanOrEqual";
})(ComparisonEnum = exports.ComparisonEnum || (exports.ComparisonEnum = {}));
var ConstructMasterGroup;
(function (ConstructMasterGroup) {
    ConstructMasterGroup["Arthroplasty"] = "Arthroplasty";
    ConstructMasterGroup["Other"] = "Other";
    ConstructMasterGroup["Spine"] = "Spine";
    ConstructMasterGroup["Trauma"] = "Trauma";
})(ConstructMasterGroup = exports.ConstructMasterGroup || (exports.ConstructMasterGroup = {}));
/** File Status Enum */
var FileStatusEnum;
(function (FileStatusEnum) {
    FileStatusEnum["Processed"] = "processed";
    FileStatusEnum["Processing"] = "processing";
    FileStatusEnum["Uploaded"] = "uploaded";
    FileStatusEnum["Uploading"] = "uploading";
})(FileStatusEnum = exports.FileStatusEnum || (exports.FileStatusEnum = {}));
var GicsOrderByEnum;
(function (GicsOrderByEnum) {
    GicsOrderByEnum["Id"] = "id";
    GicsOrderByEnum["Name"] = "name";
})(GicsOrderByEnum = exports.GicsOrderByEnum || (exports.GicsOrderByEnum = {}));
/** Grouping Type for Newsletter Grouping */
var GroupByEnum;
(function (GroupByEnum) {
    GroupByEnum["Category"] = "category";
    GroupByEnum["Date"] = "date";
})(GroupByEnum = exports.GroupByEnum || (exports.GroupByEnum = {}));
/** Update types for relation */
var ListItemUpdateType;
(function (ListItemUpdateType) {
    ListItemUpdateType["Add"] = "Add";
    ListItemUpdateType["Delete"] = "Delete";
})(ListItemUpdateType = exports.ListItemUpdateType || (exports.ListItemUpdateType = {}));
var ManufacturersOrderByEnum;
(function (ManufacturersOrderByEnum) {
    ManufacturersOrderByEnum["City"] = "city";
    ManufacturersOrderByEnum["Country"] = "country";
    ManufacturersOrderByEnum["Id"] = "id";
    ManufacturersOrderByEnum["Name"] = "name";
    ManufacturersOrderByEnum["State"] = "state";
})(ManufacturersOrderByEnum = exports.ManufacturersOrderByEnum || (exports.ManufacturersOrderByEnum = {}));
/** Type of Manufacturers */
var ManufacturerType;
(function (ManufacturerType) {
    ManufacturerType["B"] = "B";
    ManufacturerType["G"] = "G";
    ManufacturerType["I"] = "I";
    ManufacturerType["L"] = "L";
    ManufacturerType["O"] = "O";
    ManufacturerType["Other"] = "Other";
    ManufacturerType["P"] = "P";
    ManufacturerType["Unknown"] = "Unknown";
})(ManufacturerType = exports.ManufacturerType || (exports.ManufacturerType = {}));
var MaterialsOrderByEnum;
(function (MaterialsOrderByEnum) {
    MaterialsOrderByEnum["Id"] = "id";
    MaterialsOrderByEnum["LongName"] = "longName";
    MaterialsOrderByEnum["Name"] = "name";
})(MaterialsOrderByEnum = exports.MaterialsOrderByEnum || (exports.MaterialsOrderByEnum = {}));
/** Data type of new prices update */
var NewPricesDataType;
(function (NewPricesDataType) {
    NewPricesDataType["Approving"] = "approving";
    NewPricesDataType["Discarded"] = "discarded";
    NewPricesDataType["Inserting"] = "inserting";
})(NewPricesDataType = exports.NewPricesDataType || (exports.NewPricesDataType = {}));
var NewsletterOrderBy;
(function (NewsletterOrderBy) {
    NewsletterOrderBy["IdAsc"] = "id_ASC";
    NewsletterOrderBy["IdDesc"] = "id_DESC";
    NewsletterOrderBy["IssueAsc"] = "issue_ASC";
    NewsletterOrderBy["IssueDesc"] = "issue_DESC";
    NewsletterOrderBy["NameAsc"] = "name_ASC";
    NewsletterOrderBy["NameDesc"] = "name_DESC";
    NewsletterOrderBy["PublishMonthAsc"] = "publishMonth_ASC";
    NewsletterOrderBy["PublishMonthDesc"] = "publishMonth_DESC";
    NewsletterOrderBy["PublishYearAsc"] = "publishYear_ASC";
    NewsletterOrderBy["PublishYearDesc"] = "publishYear_DESC";
})(NewsletterOrderBy = exports.NewsletterOrderBy || (exports.NewsletterOrderBy = {}));
/** Paid Status for Searches and Invoices */
var PaidStatusEnum;
(function (PaidStatusEnum) {
    PaidStatusEnum["Paid"] = "paid";
    PaidStatusEnum["Pending"] = "pending";
    PaidStatusEnum["Unpaid"] = "unpaid";
})(PaidStatusEnum = exports.PaidStatusEnum || (exports.PaidStatusEnum = {}));
var PartsOrderByEnum;
(function (PartsOrderByEnum) {
    PartsOrderByEnum["Brand"] = "brand";
    PartsOrderByEnum["Gic"] = "gic";
    PartsOrderByEnum["Id"] = "id";
    PartsOrderByEnum["Id_510k"] = "id_510k";
    PartsOrderByEnum["InputName"] = "inputName";
    PartsOrderByEnum["Manufacturer"] = "manufacturer";
    PartsOrderByEnum["Material"] = "material";
    PartsOrderByEnum["PartName"] = "partName";
    PartsOrderByEnum["PartNumber"] = "partNumber";
    PartsOrderByEnum["ProductLine"] = "productLine";
    PartsOrderByEnum["QuantityPerBox"] = "quantityPerBox";
    PartsOrderByEnum["StrippedPartNumberNoZero"] = "strippedPartNumberNoZero";
    PartsOrderByEnum["TypeOne"] = "typeOne";
    PartsOrderByEnum["TypeTwo"] = "typeTwo";
    PartsOrderByEnum["Uom"] = "uom";
})(PartsOrderByEnum = exports.PartsOrderByEnum || (exports.PartsOrderByEnum = {}));
var Role;
(function (Role) {
    Role["CurvoAdmin"] = "CurvoAdmin";
    Role["DataEnrichmentAdmin"] = "DataEnrichmentAdmin";
    Role["User"] = "User";
})(Role = exports.Role || (exports.Role = {}));
/** Sort Order for Newsletter Grouping */
var SortOrderEnum;
(function (SortOrderEnum) {
    SortOrderEnum["Asc"] = "asc";
    SortOrderEnum["Desc"] = "desc";
})(SortOrderEnum = exports.SortOrderEnum || (exports.SortOrderEnum = {}));
var StringFilterType;
(function (StringFilterType) {
    StringFilterType["Contains"] = "Contains";
    StringFilterType["EndsWith"] = "EndsWith";
    StringFilterType["Equals"] = "Equals";
    StringFilterType["NotContains"] = "NotContains";
    StringFilterType["Regex"] = "Regex";
    StringFilterType["StartsWith"] = "StartsWith";
})(StringFilterType = exports.StringFilterType || (exports.StringFilterType = {}));
var StripeProductEnum;
(function (StripeProductEnum) {
    StripeProductEnum["FindAPart"] = "FindAPart";
    StripeProductEnum["Newsletter"] = "Newsletter";
    StripeProductEnum["Other"] = "Other";
})(StripeProductEnum = exports.StripeProductEnum || (exports.StripeProductEnum = {}));
var StripeSubscriptionStatus;
(function (StripeSubscriptionStatus) {
    StripeSubscriptionStatus["Active"] = "active";
    StripeSubscriptionStatus["Canceled"] = "canceled";
    StripeSubscriptionStatus["PastDue"] = "pastDue";
    StripeSubscriptionStatus["Trialing"] = "trialing";
    StripeSubscriptionStatus["Unpaid"] = "unpaid";
})(StripeSubscriptionStatus = exports.StripeSubscriptionStatus || (exports.StripeSubscriptionStatus = {}));
var StudyFileTypeEnum;
(function (StudyFileTypeEnum) {
    StudyFileTypeEnum["Contract"] = "Contract";
    StudyFileTypeEnum["Epic"] = "EPIC";
    StudyFileTypeEnum["Other"] = "Other";
    StudyFileTypeEnum["XRef"] = "XRef";
})(StudyFileTypeEnum = exports.StudyFileTypeEnum || (exports.StudyFileTypeEnum = {}));
/** Study Catalog Enum */
var StudyLinkCatalogEnum;
(function (StudyLinkCatalogEnum) {
    StudyLinkCatalogEnum["Gudid"] = "gudid";
    StudyLinkCatalogEnum["Medline"] = "medline";
    StudyLinkCatalogEnum["Stan"] = "stan";
})(StudyLinkCatalogEnum = exports.StudyLinkCatalogEnum || (exports.StudyLinkCatalogEnum = {}));
var StudyStatusEnum;
(function (StudyStatusEnum) {
    StudyStatusEnum["Archived"] = "Archived";
    StudyStatusEnum["Archiving"] = "Archiving";
    StudyStatusEnum["AutoLinking"] = "AutoLinking";
    StudyStatusEnum["AutoMatching"] = "AutoMatching";
    StudyStatusEnum["CaseReview"] = "CaseReview";
    StudyStatusEnum["Committing"] = "Committing";
    StudyStatusEnum["CommittingCase"] = "CommittingCase";
    StudyStatusEnum["Completed"] = "Completed";
    StudyStatusEnum["Failed"] = "Failed";
    StudyStatusEnum["Grooming"] = "Grooming";
    StudyStatusEnum["Grooming2"] = "Grooming2";
    StudyStatusEnum["Linking"] = "Linking";
    StudyStatusEnum["New"] = "New";
    StudyStatusEnum["QueuedAutoMatching"] = "QueuedAutoMatching";
    StudyStatusEnum["QueuedForCommitting"] = "QueuedForCommitting";
    StudyStatusEnum["QueuedForGrooming2"] = "QueuedForGrooming2";
    StudyStatusEnum["QueuedForPushingToGrooming1"] = "QueuedForPushingToGrooming1";
    StudyStatusEnum["Submitted"] = "Submitted";
})(StudyStatusEnum = exports.StudyStatusEnum || (exports.StudyStatusEnum = {}));
/** Study Transaction processing state */
var StudyTransactionState;
(function (StudyTransactionState) {
    StudyTransactionState["BamfMatching"] = "bamfMatching";
    StudyTransactionState["BlacklistStrippedItem"] = "blacklistStrippedItem";
    StudyTransactionState["BlacklistSupplier"] = "blacklistSupplier";
    StudyTransactionState["CaseReview"] = "caseReview";
    StudyTransactionState["CaseReviewDetail"] = "caseReviewDetail";
    StudyTransactionState["Conflict"] = "conflict";
    StudyTransactionState["FinalReview"] = "finalReview";
    StudyTransactionState["Hidden"] = "hidden";
    StudyTransactionState["IgnoreRow"] = "ignoreRow";
    StudyTransactionState["IgnoreSupplier"] = "ignoreSupplier";
    StudyTransactionState["SupplierMatching"] = "supplierMatching";
    StudyTransactionState["SupplierMatchingAndFinalReview"] = "supplierMatchingAndFinalReview";
    StudyTransactionState["TwinApproved"] = "twinApproved";
    StudyTransactionState["TwinMatch"] = "twinMatch";
})(StudyTransactionState = exports.StudyTransactionState || (exports.StudyTransactionState = {}));
var SuggestionItemTypeEnum;
(function (SuggestionItemTypeEnum) {
    SuggestionItemTypeEnum["Brand"] = "Brand";
    SuggestionItemTypeEnum["Gic"] = "Gic";
    SuggestionItemTypeEnum["GicTypeOne"] = "GicTypeOne";
    SuggestionItemTypeEnum["GicTypeTwo"] = "GicTypeTwo";
    SuggestionItemTypeEnum["Manufacturer"] = "Manufacturer";
    SuggestionItemTypeEnum["PartName"] = "PartName";
    SuggestionItemTypeEnum["PartNumber"] = "PartNumber";
    SuggestionItemTypeEnum["ProductLine"] = "ProductLine";
    SuggestionItemTypeEnum["Segment"] = "Segment";
    SuggestionItemTypeEnum["SubSegment"] = "SubSegment";
})(SuggestionItemTypeEnum = exports.SuggestionItemTypeEnum || (exports.SuggestionItemTypeEnum = {}));
/** Data type of queued update parts */
var UpdatePartDataType;
(function (UpdatePartDataType) {
    UpdatePartDataType["Approving"] = "approving";
    UpdatePartDataType["Discarded"] = "discarded";
    UpdatePartDataType["Inserting"] = "inserting";
})(UpdatePartDataType = exports.UpdatePartDataType || (exports.UpdatePartDataType = {}));
var UserActivationStateEnum;
(function (UserActivationStateEnum) {
    UserActivationStateEnum["Active"] = "Active";
    UserActivationStateEnum["Deactive"] = "Deactive";
    UserActivationStateEnum["Pending"] = "Pending";
})(UserActivationStateEnum = exports.UserActivationStateEnum || (exports.UserActivationStateEnum = {}));
/** User Type */
var UserTypeEnum;
(function (UserTypeEnum) {
    UserTypeEnum["Child"] = "child";
    UserTypeEnum["Parent"] = "parent";
})(UserTypeEnum = exports.UserTypeEnum || (exports.UserTypeEnum = {}));
const result = {
    "possibleTypes": {}
};
exports.default = result;
