"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchMasterDataParts = exports.MasterDataSearchFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
exports.MasterDataSearchFragment = (0, client_1.gql) `
  fragment MasterDataSearchFragment on MasterDataType {
    id
    id
    catalogNumber
    companyName
    versionModelNumber
    sku
    materialCode
    description
    manufacturer
    type
    source
    vendorCurvo
    manufacturerCurvo
  }
`;
const MASTER_DATA_SEARCH = (0, client_1.gql) `
  query searchMasterData($input: MasterDataSearchInput!) {
    searchMasterData(input: $input) {
      ...MasterDataSearchFragment
    }
  }
  ${exports.MasterDataSearchFragment}
`;
const useSearchMasterDataParts = (basicOption) => {
    return (0, utils_1.useQueryFunction)(MASTER_DATA_SEARCH, basicOption);
};
exports.useSearchMasterDataParts = useSearchMasterDataParts;
