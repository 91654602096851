"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNormalizedCurvoSuggestionsQuery = exports.bulkUpsertNormalizedSuppliersMutation = exports.useStudyNormalizedSuppliersMatchQuery = exports.createNormalizedSupplierMutation = exports.deleteNormalizedSupplierMutation = exports.updateNormalizedSupplierMutation = exports.NormalizedSuppliersData = exports.useNormalizedsuppliersQuery = exports.NORMALIZED_SUPPLIER = exports.NormalizedSupplierFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const pageInfo_1 = require("./pageInfo");
const manufacturer_1 = require("./manufacturer");
exports.NormalizedSupplierFragment = (0, client_1.gql) `
  fragment NormalizedSupplierFragment on NormalizedSupplier {
    inputSupplier
    producesReprocessedItems
    retry {
      id
      name
    }
    normalizedCurvo
    type
  }
`;
exports.NORMALIZED_SUPPLIER = (0, client_1.gql) `
  query normalizedSuppliers($before: String, $after: String, $first: Int, $last: Int, $skip: Int, $searchText: String) {
    normalizedSuppliers(
      before: $before
      after: $after
      first: $first
      last: $last
      skip: $skip
      searchText: $searchText
    ) {
      edges {
        node {
          ...NormalizedSupplierFragment
          manufacturer {
            ...ManufacturerFragment
          }
        }
      }
      metadata {
        ...DefaultMetaDataFragment
      }
    }
  }
  ${exports.NormalizedSupplierFragment}
  ${manufacturer_1.ManufacturerFragment}
  ${pageInfo_1.DefaultMetaDataFragment}
`;
const useNormalizedsuppliersQuery = (baseOption) => {
    return (0, utils_1.useQueryFunction)(exports.NORMALIZED_SUPPLIER, baseOption);
};
exports.useNormalizedsuppliersQuery = useNormalizedsuppliersQuery;
exports.NormalizedSuppliersData = (0, utils_1.buildQuery)({
    query: exports.NORMALIZED_SUPPLIER,
});
const UPDATE_NORMALIZED_SUPPLIER = (0, client_1.gql) `
  mutation updateNormalizedSupplier($input: NormalizedSupplierUpdateInput!) {
    updateNormalizedSupplier(input: $input) {
      ...NormalizedSupplierFragment
      manufacturer {
        ...ManufacturerFragment
      }
    }
  }
  ${exports.NormalizedSupplierFragment}
  ${manufacturer_1.ManufacturerFragment}
`;
exports.updateNormalizedSupplierMutation = (0, utils_1.mutateFunction)({
    mutation: UPDATE_NORMALIZED_SUPPLIER,
});
const DELETE_NORMALIZED_SUPPLIER = (0, client_1.gql) `
  mutation deleteNormalizedSupplier($inputSupplier: String!, $normalizedCurvo: String) {
    deleteNormalizedSupplier(inputSupplier: $inputSupplier, normalizedCurvo: $normalizedCurvo) {
      ...NormalizedSupplierFragment
    }
  }
  ${exports.NormalizedSupplierFragment}
`;
exports.deleteNormalizedSupplierMutation = (0, utils_1.mutateFunction)({
    mutation: DELETE_NORMALIZED_SUPPLIER,
});
const CREATE_NORMALIZED_SUPPLIER = (0, client_1.gql) `
  mutation createNormalizedSupplier($input: NormalizedSupplierCreateInput!) {
    createNormalizedSupplier(input: $input) {
      ...NormalizedSupplierFragment
    }
  }
  ${exports.NormalizedSupplierFragment}
`;
exports.createNormalizedSupplierMutation = (0, utils_1.mutateFunction)({
    mutation: CREATE_NORMALIZED_SUPPLIER,
});
const STUDY_NORMALZIED_SUPPLIERS_MATCH = (0, client_1.gql) `
  query studyNormalizedSuppliersMatch($id: Int!) {
    studySuppliersMatch(id: $id) {
      inputSupplier
      retry {
        id
        name
      }
      normalizedCurvo
      type
      manufacturer {
        id
        name
      }
      totalSpend
    }
  }
`;
const useStudyNormalizedSuppliersMatchQuery = (baseOption) => (0, utils_1.useQueryFunction)(STUDY_NORMALZIED_SUPPLIERS_MATCH, baseOption);
exports.useStudyNormalizedSuppliersMatchQuery = useStudyNormalizedSuppliersMatchQuery;
const BULK_UPDATE_NORMALIZED_SUPPLIER = (0, client_1.gql) `
  mutation bulkUpsertNormalizedSuppliers($input: [NormalizedSupplierUpdateInput!]!) {
    bulkUpsertNormalizedSuppliers(input: $input) {
      ...NormalizedSupplierFragment
      manufacturer {
        id
        name
      }
    }
  }
  ${exports.NormalizedSupplierFragment}
`;
exports.bulkUpsertNormalizedSuppliersMutation = (0, utils_1.mutateFunction)({
    mutation: BULK_UPDATE_NORMALIZED_SUPPLIER,
});
const NORMALIZED_CURVO_SUGGESTIONS = (0, client_1.gql) `
  query normalizedCurvoSuggestions($searchText: String!) {
    normalizedCurvoSuggestions(searchText: $searchText)
  }
`;
const useNormalizedCurvoSuggestionsQuery = (baseOption) => (0, utils_1.useQueryFunction)(NORMALIZED_CURVO_SUGGESTIONS, baseOption);
exports.useNormalizedCurvoSuggestionsQuery = useNormalizedCurvoSuggestionsQuery;
