"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAdvancedSearchData = exports.ADVANCED_PARTS_SEARCH_QUERY_WITHOUT_PAGINATION = exports.ADVANCED_PARTS_SEARCH_QUERY = exports.createPartMutation = exports.deletePartMutation = exports.PART_DELETE_MUTATION = exports.bulkPartUpdateMutation = exports.BULK_PART_UPDATE = exports.partUpdateMutation = exports.usePartsData = exports.PART_UPDATE = exports.PartsForSelectionData = exports.PartsData = exports.PARTS_WITHOUT_PAGINATION_QUERY = exports.PARTS_FOR_SELECTION_QUERY = exports.PARTS_QUERY = exports.PartImagesData = exports.PartData = exports.PartFragment = exports.PartExtraFragment = void 0;
const client_1 = require("@apollo/client");
const utils_1 = require("../helpers/utils");
const brand_1 = require("./brand");
const gic_1 = require("./gic");
const gicTypeOne_1 = require("./gicTypeOne");
const gicTypeTwo_1 = require("./gicTypeTwo");
const manufacturer_1 = require("./manufacturer");
const material_1 = require("./material");
const pageInfo_1 = require("./pageInfo");
const productLine_1 = require("./productLine");
const segmentation_1 = require("./segmentation");
exports.PartExtraFragment = (0, client_1.gql) `
  fragment PartExtraFragment on Part {
    id_510k
    description
    uom
    partAttributes
    dataSource
    side
    isCustom
    isReprocessed
    isSterile
    quantityPerBox
    mfgSize
    mtlProductLine
  }
`;
exports.PartFragment = (0, client_1.gql) `
  fragment PartFragment on Part {
    id
    partName
    partNumber
    strippedPartNumber
    inputName
    sizeOne
    sizeTwo
    sizeThree
    udiId
    createdAt
    manufacturer {
      ...ManufacturerFragment
    }
    brand {
      ...BrandFragment
    }
    productLine {
      ...ProductLineFragment
    }
    gic {
      ...GicFragment
    }
    typeOne {
      ...GicTypeOneFragment
    }
    typeTwo {
      ...GicTypeTwoFragment
    }
    material {
      ...MaterialFragment
    }
    segmentation {
      ...SegmentationFragment
    }
  }
  ${material_1.MaterialFragment}
  ${gicTypeTwo_1.GicTypeTwoFragment}
  ${gicTypeOne_1.GicTypeOneFragment}
  ${gic_1.GicFragment}
  ${productLine_1.ProductLineFragment}
  ${brand_1.BrandFragment}
  ${manufacturer_1.ManufacturerFragment}
  ${segmentation_1.SegmentationFragment}
`;
const PART_QUERY = (0, client_1.gql) `
  query part($id: ID!) {
    part(id: $id) {
      ...PartFragment
    }
  }
  ${exports.PartFragment}
`;
exports.PartData = (0, utils_1.buildQuery)({
    query: PART_QUERY,
});
const PART_IMAGES = (0, client_1.gql) `
  query partImages($partId: String!) {
    partImages(partId: $partId)
  }
`;
exports.PartImagesData = (0, utils_1.buildQuery)({
    query: PART_IMAGES,
});
exports.PARTS_QUERY = (0, client_1.gql) `
  query parts(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $skip: Int
    $args: PartFilterInput
    $searchText: String
    $orderBy: [PartOrderingInput!]
    $isInactive: Boolean
    $simpleSearch: Boolean
  ) {
    parts(
      before: $before
      after: $after
      first: $first
      last: $last
      searchText: $searchText
      skip: $skip
      args: $args
      orderBy: $orderBy
      isInactive: $isInactive
      simpleSearch: $simpleSearch
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...PartFragment
          ...PartExtraFragment
        }
        cursor
      }
      metadata {
        ...DefaultMetaDataFragment
      }
    }
  }
  ${exports.PartFragment}
  ${exports.PartExtraFragment}
  ${pageInfo_1.PageInfoFragment}
  ${pageInfo_1.DefaultMetaDataFragment}
`;
exports.PARTS_FOR_SELECTION_QUERY = (0, client_1.gql) `
  query parts(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $skip: Int
    $args: PartFilterInput
    $searchText: String
    $orderBy: [PartOrderingInput!]
    $isInactive: Boolean
    $simpleSearch: Boolean
  ) {
    parts(
      before: $before
      after: $after
      first: $first
      last: $last
      searchText: $searchText
      skip: $skip
      args: $args
      orderBy: $orderBy
      isInactive: $isInactive
      simpleSearch: $simpleSearch
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          id
          partName
          partNumber
          strippedPartNumber
          inputName
          sizeOne
          sizeTwo
          sizeThree
          udiId
          createdAt
        }
        cursor
      }
      metadata {
        ...DefaultMetaDataFragment
      }
    }
  }
  ${pageInfo_1.PageInfoFragment}
  ${pageInfo_1.DefaultMetaDataFragment}
`;
exports.PARTS_WITHOUT_PAGINATION_QUERY = (0, client_1.gql) `
  query partsWithoutPagination(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $skip: Int
    $args: PartFilterInput
    $searchText: String
    $orderBy: [PartOrderingInput!]
    $isInactive: Boolean
    $simpleSearch: Boolean
  ) {
    partsWithoutPagination(
      before: $before
      after: $after
      first: $first
      last: $last
      searchText: $searchText
      skip: $skip
      args: $args
      orderBy: $orderBy
      isInactive: $isInactive
      simpleSearch: $simpleSearch
    ) {
      ...PartFragment
      ...PartExtraFragment
    }
  }
  ${exports.PartFragment}
  ${exports.PartExtraFragment}
`;
exports.PartsData = (0, utils_1.buildQuery)({
    query: exports.PARTS_QUERY,
});
exports.PartsForSelectionData = (0, utils_1.buildQuery)({
    query: exports.PARTS_FOR_SELECTION_QUERY,
});
exports.PART_UPDATE = (0, client_1.gql) `
  mutation updatePart($input: PartUpdateInput!) {
    updatePart(input: $input) {
      ...PartFragment
      ...PartExtraFragment
    }
  }
  ${exports.PartFragment}
  ${exports.PartExtraFragment}
`;
const usePartsData = (options) => {
    return (0, utils_1.useQueryFunction)(exports.PARTS_QUERY, options);
};
exports.usePartsData = usePartsData;
exports.partUpdateMutation = (0, utils_1.mutateFunction)({
    mutation: exports.PART_UPDATE,
});
exports.BULK_PART_UPDATE = (0, client_1.gql) `
  mutation bulkUpdatePart($input: [PartUpdateInput!]!) {
    bulkUpdatePart(input: $input) {
      ...PartFragment
      ...PartExtraFragment
    }
  }
  ${exports.PartFragment}
  ${exports.PartExtraFragment}
`;
exports.bulkPartUpdateMutation = (0, utils_1.mutateFunction)({
    mutation: exports.BULK_PART_UPDATE,
});
exports.PART_DELETE_MUTATION = (0, client_1.gql) `
  mutation deletePart($id: String!) {
    deletePart(id: $id) {
      ...PartFragment
    }
  }
  ${exports.PartFragment}
`;
exports.deletePartMutation = (0, utils_1.mutateFunction)({
    mutation: exports.PART_DELETE_MUTATION,
});
const PART_CREATE_MUTATION = (0, client_1.gql) `
  mutation createPart($input: PartCreateInput!) {
    createPart(input: $input) {
      ...PartFragment
    }
  }
  ${exports.PartFragment}
`;
exports.createPartMutation = (0, utils_1.mutateFunction)({
    mutation: PART_CREATE_MUTATION,
});
exports.ADVANCED_PARTS_SEARCH_QUERY = (0, client_1.gql) `
  query advancedSearch(
    $searchText: String
    $first: Int = 100
    $skip: Int
    $args: AdvancedSearchPartInput
    $sizeConditions: AdvancedSizeConditionInput
    $partialPartNumber: String
    $partialPartDesc: String
    $manufacturerId: String
    $orderBy: [PartOrderingInput!]
  ) {
    advancedSearch(
      searchText: $searchText
      first: $first
      skip: $skip
      args: $args
      sizeConditions: $sizeConditions
      partialPartNumber: $partialPartNumber
      partialPartDesc: $partialPartDesc
      manufacturerId: $manufacturerId
      orderBy: $orderBy
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          ...PartFragment
          ...PartExtraFragment
        }
        cursor
      }
      metadata {
        ...DefaultMetaDataFragment
      }
    }
  }
  ${exports.PartFragment}
  ${exports.PartExtraFragment}
  ${pageInfo_1.PageInfoFragment}
  ${pageInfo_1.DefaultMetaDataFragment}
`;
exports.ADVANCED_PARTS_SEARCH_QUERY_WITHOUT_PAGINATION = (0, client_1.gql) `
  query advancedSearchWithoutPagination(
    $searchText: String
    $first: Int = 100
    $skip: Int
    $args: AdvancedSearchPartInput
    $sizeConditions: AdvancedSizeConditionInput
    $partialPartNumber: String
    $partialPartDesc: String
    $manufacturerId: String
    $orderBy: [PartOrderingInput!]
  ) {
    advancedSearchWithoutPagination(
      searchText: $searchText
      first: $first
      skip: $skip
      args: $args
      sizeConditions: $sizeConditions
      partialPartNumber: $partialPartNumber
      partialPartDesc: $partialPartDesc
      manufacturerId: $manufacturerId
      orderBy: $orderBy
    ) {
      ...PartFragment
      ...PartExtraFragment
    }
  }
  ${exports.PartFragment}
  ${exports.PartExtraFragment}
`;
const useAdvancedSearchData = (options) => {
    return (0, utils_1.useQueryFunction)(exports.ADVANCED_PARTS_SEARCH_QUERY, options);
};
exports.useAdvancedSearchData = useAdvancedSearchData;
